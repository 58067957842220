// @font-face {
//   font-family: "AlibabaPuHuiTiB";
//   src: url("./Alibaba-PuHuiTi-Bold.ttf") format("truetype");
//   font-style: normal;
//   font-display: swap;
// }
// .alibaba-puhuitib {
//   font-family: "AlibabaPuHuiTiB";
//   font-style: normal;
//   font-weight: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "AlibabaPuHuiTiB-a";
  src: url("./Alibaba-PuHuiTi-Bold-a.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AlibabaPuHuiTiB-b";
  src: url("./Alibaba-PuHuiTi-Bold-b.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AlibabaPuHuiTiB-c";
  src: url("./Alibaba-PuHuiTi-Bold-c.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}
.alibaba-puhuitib {
  font-family: AlibabaPuHuiTiB-a, AlibabaPuHuiTiB-b, AlibabaPuHuiTiB-c;
  font-style: normal;
  font-weight: bolder;
  font-display: swap;
}
